import React from 'react';
import '../../css/slider/textone.css';

function TextOne() {
  return (
    <div className="col-2 textone">
      <h3 className="highlight white">En Colombia hay</h3>
      <h1 className="highlight babyblue">865</h1>
      <h2 className="highlight babyblue">aeropuertos</h2>
      <h2 className="highlight babyblue">y aeródromos</h2>
      <div className="paragraph white">
        <p>Actualmente hay 70 aeropuertos, de los cuales 16 son internacionales, 18 son nacionales y 36 son regionales. También hay 795 aeródromos, que son <b>campos de vuelo de tamaño menor al de un aeropuerto</b>. Estos aeródromos se utilizan, en su mayoría, para <b>labores de fumigación y para uso privado.</b></p>
      </div>
    </div>
  );
}

export default TextOne;
