import '../css/footer.css';
import React from 'react';

const Footer = () => (
  <footer class="footer">
    <p>Todo el material en esta página, cuando no se indique lo contrario, es <a href="https://creativecommons.org/licenses/by-sa/4.0/" target="_blank" rel="noopener noreferrer">CC-BY-SA</a>. Si reusas o adaptas recursos de esta página por favor vincula y referencia nuestro artículo o página.</p>
    <p style={{ marginTop: '0.5rem' }}>En caso de tener alguna pregunta o sugerencia <a href="https://www.infraestructuravisible.org/contacto" target="_blank" rel="noopener noreferrer">comunícate con nosotros</a></p>
  </footer>
)

export default Footer;
