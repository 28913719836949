import React from 'react';
import '../../css/slider/textsix.css';

function TextSix() {
  return (
    <div className="col-2 textsix">
      <h1 className="highlight babyblue">256</h1>
      <h1 className="highlight babyblue">aerolíneas</h1>
      <h2 className="highlight darkblue">han operado en</h2>
      <h2 className="highlight darkblue">Colombia desde 2004</h2>
      <div className="paragraph darkblue">
        <p>Desde 2004 hasta 2018, las 3 aerolíneas con mayor número de vuelos nacionales son <b>Avianca</b>, <b>Aires (actualmente conocida como LAN Airlines o LATAM)</b> y <b>Satena</b>. Por otro lado, las 3 aerolíneas con mayor número de vuelos internacionales registrados son <b>Avianca</b>, <b>Aerorepública (actualmente Copa Airlines)</b> y <b>Tampa Cargo S.A. (aerolínea de carga de Avianca)</b>. Le sigue <b>American Airlines.</b></p>
      </div>
    </div>
  );
}

export default TextSix;
