import React from 'react';
import '../../css/slider/graphictwo.css';

class GraphicTwo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {selectedGraphic: "aeropuertos"};
  }

  handleSelect(id, e) {
    id === "bt1" ? this.setState({selectedGraphic: "aeropuertos"}) : this.setState({selectedGraphic: "aerodromos"});
    e.preventDefault();
    e.stopPropagation();
  }

  render() {
    return (
      <div className="col-2 graphictwo">
        <iframe id='gr2' title="Numero de aeropuertos/aerodromos por departamento" src={this.state.selectedGraphic === "aeropuertos" ? "/graphics/gf1.html" : "/graphics/gf2.html"} frameBorder="0"></iframe>
        <div className="buttons">
          <button type="button" onClick={(e) => this.handleSelect("bt1", e)} className={this.state.selectedGraphic === "aeropuertos" ? 'selected' : ''}>AEROPUERTOS</button>
          <button type="button" onClick={(e) => this.handleSelect("bt2", e)} className={this.state.selectedGraphic === "aerodromos" ? 'selected' : ''}>AERÓDROMOS</button>
        </div>
      </div>
    );
  }
}

export default GraphicTwo;
