import React from 'react';
import '../../css/slider/graphicone.css';

function GraphicOne() {
  return (
    <div className="col-2 graphicone">
      <iframe id='gr1' title="Numero de aeropuertos por categoria y uso" src="/graphics/gf0.html" frameBorder="0"></iframe>
    </div>
  );
}

export default GraphicOne;
