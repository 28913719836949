import React from 'react';
import '../../css/slider/texttwo.css';

function TextTwo() {
  return (
    <div className="col-2 texttwo">
      <h1 className="highlight babyblue">Antioquia</h1>
      <h1 className="highlight babyblue">y Chocó</h1>
      <h2 className="highlight darkblue">son los departamentos</h2>
      <h2 className="highlight darkblue">con más aeropuertos</h2>
      <div className="paragraph darkblue">
        <p>Todos los departamentos tienen entre 1 y 3 aeropuertos (regionales, nacionales o internacionales) exceptuando <b>Antioquia, que tiene 8, y Chocó, que tiene 6</b>. Los departamentos con mayor número de aeródromos son <b>Meta y Casanare, con 120 y 188 respectivamente</b>. En contraste, Putumayo y Risaralda solo tienen 1 aeródromo cada uno.</p>
      </div>
    </div>
  );
}

export default TextTwo;
