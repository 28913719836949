import React from 'react';
import '../../css/slider/textseven.css';

function TextSeven() {
  return (
    <div className="col-2 textseven">
      <h2 className="highlight babyblue">858,210</h2>
      <h3 className="highlight babyblue">toneladas</h3>
      <h3 className="highlight white">de carga a bordo</h3>
      <h3 className="highlight white">fueron transportadas</h3>
      <h3 className="highlight babyblue">en el 2018</h3>
      <div className="paragraph white">
        <p>En este mismo año, los aeropuertos con mayores registros de carga (entrante y saliente) fueron: El Dorado <b>con 723,015.44 tn</b>, Jose María Cordova <b>con 123,006.89 tn</b> y Alfonso Bonilla Aragón <b>con 40,290.19 tn</b>. El mismo ranking aplica para los trayectos internacionales y nacionales.</p>
      </div>
    </div>
  );
}

export default TextSeven;
