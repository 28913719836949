import React from 'react';
import '../../css/slider/graphicsix.css';

class GraphicSix extends React.Component {
  constructor(props) {
    super(props);
    this.state = {selectedGraphic: "nacionales"};
  }

  handleSelect(id, e) {
    id === "bt1" ? this.setState({selectedGraphic: "nacionales"}) : this.setState({selectedGraphic: "internacionales"});
    e.preventDefault();
  }

  render() {
    return (
      <div className="col-2 graphicsix">
        <iframe id='gr7' title="Numero de aeropuertos/aerodromos por departamento" src={this.state.selectedGraphic === "nacionales" ? "/graphics/gf7.html" : "/graphics/gf8.html"} frameBorder="0"></iframe>
        <div className="buttons">
          <button type="button" onClick={(e) => this.handleSelect("bt1", e)} className={this.state.selectedGraphic === "nacionales" ? 'selected' : ''}>NACIONALES</button>
          <button type="button" onClick={(e) => this.handleSelect("bt2", e)} className={this.state.selectedGraphic === "internacionales" ? 'selected' : ''}>INTERNACIONALES</button>
        </div>
      </div>
    );
  }
}

export default GraphicSix;
