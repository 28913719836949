import React from 'react';
import '../css/download.css';

function Download() {
  return (
    <div className="section download">
      <div className="col-1">
        <h2 className="title white">DESCARGA DE</h2>
        <h2 className="title babyblue">DATOS</h2>
      </div>
      <div className="col-1">
        <div className="paragraph white">
          <p id="text3">Los datos que fueron utilizados en este especial <b>están disponibles para que la ciudadanía los descargue</b>, pueda verificar la información mostrada y pueda realizar sus propias visualizaciones o responder sus propias preguntas.</p>
          <div className="buttons bottom">
            <button type="button"><a href="https://s3.amazonaws.com/data.infraestructuravisible.org/aeropuertos_historico_colombia_data.csv" rel="noopener noreferrer" target="_blank">DESCARGAR</a></button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Download;
