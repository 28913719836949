import React from 'react';
import '../../css/slider/graphicseven.css';

function GraphicSeven() {
  return (
    <div className="col-2 graphicseven">
      <iframe id='gr3' title="Numero de aeropuertos por categoria y uso" src="/graphics/gf4111.html" frameBorder="0"></iframe>
    </div>
  );
}

export default GraphicSeven;

