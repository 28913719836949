import React from 'react';
import CarouselMobile from '../js/CarouselMobile';
import CarouselDesktop from '../js/CarouselDesktop';
import '../css/carousel.css';

class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isMobile: window.innerWidth <= 980};
  }
  render() {
    if(this.state.isMobile) {
      return (
        <div id="carousel">
          <CarouselMobile />
        </div>
      );
    } else {
      return (
        <div id="carousel">
          <CarouselDesktop />
        </div>
      );
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    let currentIsMobile = (window.innerWidth <= 980);
    if (currentIsMobile !== this.state.isMobile) {
        this.setState({isMobile: currentIsMobile});
    }
  }

}

export default Carousel;
