import React from 'react';
import '../../css/slider/textfive.css';

function TextFive() {
  return (
    <div className="col-2 textfive">
      <h1 className="highlight darkblue">2,260</h1>
      <h2 className="highlight darkblue">rutas distintas</h2>
      <h3 className="highlight white">entre Colombia y el mundo (2018)</h3>
      <div className="paragraph white">
        <p>De 2004 a 2018 la ruta entre <b>Bogotá y Medellín</b> tuvo el mayor número de frecuencias, con 177,598 vuelos. Le sigue la ruta entre <b>Bogotá y Cali</b> con 158,453 vuelos. De los internacionales, el trayecto entre <b>Bogotá y Miami</b> fue el más frecuente, con 65,509 vuelos. Le sigue la ruta entre <b>Bogotá y Tocumen (Ciudad de Panamá)</b> con 53,502 vuelos y la ruta entre <b>Bogotá y Quito</b> con 34,773 vuelos.</p>
      </div>
    </div>
  );
}

export default TextFive;
