import React from 'react';
import '../css/header.css';

function Header() {
  return (
    <div className="header" id="header">
      <h2 className="title babyblue">TRANSPORTE</h2>
      <h1 className="title white">AÉREO</h1>
      <div className="paragraph white">
        <p>¿Cuántos aeropuertos hay en Colombia? ¿Para qué se usan? ¿Cuántos vuelos salen y llegan? En este especial te mostraremos datos interesantes sobre <b>aeropuertos, vuelos y aerolíneas</b> para que puedas conocer la respuesta a estas preguntas y a otras más.</p>
      </div>
    </div>
  );
}

export default Header;
