import React from 'react';
import ReactDOM from 'react-dom';
import MoveTo from 'moveto';
import './css/reset.css';
import './css/index.css';
import Navbar from './js/Navbar';
import Header from './js/Header';
import Carousel from './js/Carousel';
import Download from './js/Download';
import Footer from './js/Footer';

class App extends React.Component {

  render() {
    return(<div className="body" id="app">
      <Navbar />
      <Header />
      <Carousel />
      <Download />
      <Footer />
    </div>
  )}

  componentDidMount() {
    const moveTo = new MoveTo();
    const target = document.getElementById("carousel");
    const cb = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          moveTo.move(target)
        }
      })
    }
    const observer = new IntersectionObserver(cb, {
      threshold: 1
    })
    observer.observe(target)
  }

}

export default App;

ReactDOM.render(
  <App />, document.getElementById('root'));
