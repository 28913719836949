import React from 'react';
import '../../css/slider/graphicfour.css';

function GraphicFour() {
  return (
    <div className="col-2 graphicfour">
      <iframe id='gr4' title="Numero de aeropuertos por categoria y uso" src="/graphics/gf40.html" frameBorder="0"></iframe>
    </div>
  );
}

export default GraphicFour;
