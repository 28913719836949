import React from 'react';
import '../../css/slider/textthree.css';

function TextThree() {
  return (
    <div className="col-2 textthree">
      <h3 className="highlight white">Al año se hacen</h3>
      <h1 className="highlight darkblue">428,373</h1>
      <h1 className="highlight darkblue">vuelos</h1>
      <h2 className="highlight white">en promedio</h2>
      <div className="paragraph white">
        <p>Entre 2004 y 2018, el mayor número de vuelos se realizó en 2018 (<b>494,222</b>) y el menor en 2004 (<b>327,835</b>). Aunque los trayectos internacionales han tenido un <b>crecimiento casi constante</b>, el número de vuelos nacionales no. Aumentó drásticamente en 2010, tuvo su máximo en 2014 y decreció desde este año hasta 2017. <b>En 2018, este número aumentó de nuevo.</b></p>
      </div>
    </div>
  );
}

export default TextThree;
