import React from 'react';
import '../../css/slider/graphicthree.css';

function GraphicThree() {
  return (
    <div className="col-2 graphicthree">
      <iframe id='gr3' title="Numero de aeropuertos por categoria y uso" src="/graphics/gf3.html" frameBorder="0"></iframe>
    </div>
  );
}

export default GraphicThree;
