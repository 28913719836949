import React from 'react';
import '../../css/slider/textfour.css';

function TextFour() {
  return (
    <div className="col-2 textfour">
      <h1 className="highlight babyblue">42,146,457</h1>
      <h1 className="highlight babyblue">pasajeros</h1>
      <h2 className="highlight white">viajaron en 2018</h2>
      <div className="paragraph white">
        <p>De 2004 a 2018 <b>el número de pasajeros por año ha crecido a una tasa aproximada del  8.9%.</b> Este crecimiento, en los trayectos nacionales, ha sido del 8.3% y en los internacionales del 10.3%, aproximadamente. Se estima que el número de pasajeros en trayectos nacionales e internacionales en 2025 será de 47,442,854 y 29,786,127 respectivamente.</p>
      </div>
    </div>
  );
}

export default TextFour;
