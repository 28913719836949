import React from 'react';
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
import TextOne from '../js/slider/TextOne';
import GraphicOne from '../js/slider/GraphicOne';
import TextTwo from '../js/slider/TextTwo';
import GraphicTwo from '../js/slider/GraphicTwo';
import TextThree from '../js/slider/TextThree';
import GraphicThree from '../js/slider/GraphicThree';
import TextFour from '../js/slider/TextFour';
import GraphicFour from '../js/slider/GraphicFour';
import TextFive from '../js/slider/TextFive';
import GraphicFive from '../js/slider/GraphicFive';
import TextSix from '../js/slider/TextSix';
import GraphicSix from '../js/slider/GraphicSix';
import GraphicSeven from '../js/slider/GraphicSeven';
import TextSeven from '../js/slider/TextSeven';
import '../css/carousel.css';

class CarouselDesktop extends React.Component {
  constructor(props) {
    super(props);
    this.swiperContainer = React.createRef();
  }
  render() {
    return (
      <div className="carousel">
        <div className="swiper-container" ref={this.swiperContainer}>
          <div className="swiper-wrapper">
            <div className="swiper-slide bg-darkblue">
              <div className="section">
                <TextOne />
                <GraphicOne />
              </div>
            </div>
            <div className="swiper-slide bg-white">
              <div className="section texture-white">
                <GraphicTwo />
                <TextTwo />
              </div>
            </div>
            <div className="swiper-slide bg-babyblue">
              <div className="section">
                <TextThree />
                <GraphicThree />
              </div>
            </div>
            <div className="swiper-slide bg-darkblue">
              <div className="section texture-blue">
                <GraphicFour />
                <TextFour />
              </div>
            </div>
            <div className="swiper-slide bg-babyblue">
              <div className="section">
                <TextFive />
                <GraphicFive />
              </div>
            </div>
            <div className="swiper-slide bg-white">
              <div className="section texture-white">
                <GraphicSix />
                <TextSix />
              </div>
            </div>
            <div className="swiper-slide bg-darkblue">
              <div className="section">
                <TextSeven />
                <GraphicSeven />
              </div>
            </div>
          </div>
          <div className="swiper-pagination"></div>
          <div className="swiper-button-prev"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
    );
  }

  componentDidMount(){

    //Create the swiper carousel
    const swiper = new Swiper (this.swiperContainer.current, {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        // autoHeight: true,
        allowTouchMove: false,

        // If we need pagination
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        },

        autplay: true,

        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },

        // And if we need scrollbar
        scrollbar: {
          el: '.swiper-scrollbar',
        },
      })
      swiper.on('slideChange', function () {
        console.log('slide changed');
      });
  }

}

export default CarouselDesktop;
